import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/footer.jsx";
import Navbar from "./components/navbar/navbar.jsx";
import "./App.scss";
import Homepage from "./components/homepage/homepage.jsx";
import Pagesecond from "./components/pagesecond/pagesecond.jsx";
import Pagethird from "./components/pagethird/pagethird.jsx";
import Contact from "./components/navbar/contact/Contact.jsx";
import Pricing from "./components/navbar/pricing/pricing.jsx";
import Pdf from "./components/navbar/docs/Paylayercore_API_Doc_V002.pdf";
import BackOfficePage from "./components/navbar/products/backoffice/adminbackoffice.jsx";
import Features from "./components/navbar/features/management.jsx";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar className="navbar" />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Homepage />
                <Pagesecond />
                <Pagethird />
              </>
            }
          />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/adminbackoffice"
            element={<BackOfficePage officeType="admin" />}
          />
          <Route
            path="/merchantbackoffice"
            element={<BackOfficePage officeType="merchant" />}
          />
          <Route
            path="/cryptocommerce"
            element={<BackOfficePage officeType="cryptocommerce" />}
          />
          <Route
            path="/companybackoffice"
            element={<BackOfficePage officeType="companybackoffice" />}
          />
          <Route
            path="/panel"
            element={<BackOfficePage officeType="panel" />}
          />
          <Route
            path="/reportingservice"
            element={<BackOfficePage officeType="reportingservice" />}
          />

          <Route
            path="/paymentmethods"
            element={<Features officeType="paymentmethods" />}
          />
          <Route
            path="/poolsystem"
            element={<Features officeType="poolsystem" />}
          />
          <Route
            path="/transactionallimitsmanagement"
            element={<Features officeType="transactionallimitsmanagement" />}
          />
          <Route
            path="/merchantmanagement"
            element={<Features officeType="merchantmanagement" />}
          />
          <Route
            path="/companymanagement"
            element={<Features officeType="companymanagement" />}
          />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/docs" />
        </Routes>
      </div>
      <Footer className="footer" />
    </Router>
  );
}

export default App;
