import React from 'react';
import './footer.scss';
import linkedin from '../../images/linkedin.png';
import instagram from '../../images/instagram.png';
import github from '../../images/github.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section left">
        <span>@2024 Propay Tech SRL.</span>
      </div>
      <div className="footer-section right">
        <a href="/#" target="_blank" rel="noopener noreferrer" >
          <img src={linkedin} alt="LinkedIn" width={25}/>
        </a>
        <a href="/#" target="_blank" rel="noopener noreferrer">
          <img src={instagram} alt="Instagram" width={25}/>
        </a>
        <a href="/#" target="_blank" rel="noopener noreferrer">
          <img src={github} alt="GitHub" width={25}/>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
