import React, { useState } from "react";
import "./pagethird.scss";
import { Tooltip, Area, AreaChart, ResponsiveContainer } from "recharts";

const Pagethird = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleLineClick = (e) => {
    setShowMenu(true);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataItem = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <div style={{ display: "flex", marginBottom:'0.5vh'}}>
            <span className="label" style={{ fontWeight: 800 }}>
              {dataItem.name}
            </span>
          </div>
          <div className="line"> </div>
          <div
            style={{
              fontWeight: 800,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:'0.6vh'
            }}
          >
            <span className="label">Total</span>
            <span className="intro">€ {payload[0].value}</span>
          </div>{" "}
        </div>
      );
    }

    return null;
  };

  const data = [
    { name: "Oct 5", VisaMastercard: 1000 },
    { name: "Oct 6", VisaMastercard: 9000 },
    { name: "Oct 7", VisaMastercard: 3000 },
    { name: "Oct 8", VisaMastercard: 8200 },
    { name: "Oct 9", VisaMastercard: 1500 },
    { name: "Oct 10", VisaMastercard: 7580 },
    { name: "Oct 11", VisaMastercard: 3000 },
    { name: "Oct 12", VisaMastercard: 5000 },
    { name: "Oct 13", VisaMastercard: 10000 },
    { name: "Oct 14", VisaMastercard: 3700 },
  ];
  return (
    <div className="thirdcontent">
      <div className="area">
        <span className="atext">Empowering Your Business</span>
        <p style={{ fontSize: "1.7vw", color: "rgb(63, 63, 63)" }}>
          Payment Processing and Advanced Management Tailored to empower
          merchants, Pay Layer Core facilitates not just payment processing but
          also robust management and reporting features from a single dashboard,
          optimizing operational efficiency and securing your financial
          ecosystem against fraud.
        </p>
      </div>
      <div className="dashboard">
        <div className="cardchart">
          <ResponsiveContainer width="110%" aspect={5}>
            <AreaChart data={data} onClick={handleLineClick}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#84a2fa" stopOpacity={0.5} />
                  <stop offset="100%" stopColor="#9cb3f7" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="VisaMastercard"
                stroke="#173FF5"
                strokeWidth={5}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
          
          <div className="text">
            <h1>
              Payments, insights, company, merchant, accounting—all streamlined
              in one place.{" "}
            </h1>
            <p>
              Utilize your Admin Pay Layer Core dashboard to manage diverse
              payment methods, gain valuable insights and simplify your
              accounting processes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagethird;
