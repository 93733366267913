import React from "react";
import "./carouseltwo.scss";

import { RiUser3Line } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import scheme from "../../../../images/hierarchy.png";
import capital from "../../../../images/capital.jpeg";
import up from "../../../../images/up-arrow.png";
import back from "../../../../images/carouseltwoback.jpg";

import master from "../../../../images/master.png";
import amex from "../../../../images/amex.png";
import bank from "../../../../images/bank.png";
import card from "../../../../images/credit-card.png";

function CarouselTwo() {
  const data = [
    { date: "2023-07-15", time: "12:00", value: 30 },
    { date: "2003-09-01", time: "18:00", value: 130 },
    { date: "2024-01-20", time: "01:30", value: 50 },
    { date: "2024-01-20", time: "01:30", value: 60 },
    { date: "2024-03-18", time: "01:30", value: 65 },
    { date: "2024-04-08", time: "01:30", value: 70 },
    { date: "2024-09-01", time: "01:00", value: 110 },
    { date: "2024-12-22", time: "01:00", value: 10 },
    { date: "2024-12-22", time: "01:00", value: 90 },
    { date: "2024-12-22", time: "01:00", value: 30 },
    { date: "2024-12-22", time: "01:00", value: 200 },
    { date: "2024-12-22", time: "01:00", value: 130 },
  ];

  const payments = [
    {
      image: master,
      amount: "€ 26.95",
      status: "Paid",
      date: "Today, 18:56",
    },
    {
      image: amex,
      amount: "€ 30.45",
      status: "Paid",
      date: "26 Jan 24, 15:16",
    },
    {
      image: bank,
      amount: "€ 45.65",
      status: "Paid",
      date: "25 Jan 24, 16:00",
    },
    {
      image: card,
      amount: "€ 120.00",
      status: "Paid",
      date: "22 Jan 24, 19:15",
    },
  ];

  const Navbar = () => {
    return (
      <>
        <div className="navbar-brand">
          <a href="/">Pay Layer Core</a>
        </div>
        <div className="navbar-link">
          <AiOutlineHome size={"4%"} color="white" />
          <span>Home</span>
          <img src={scheme} width={"4%"} height={"4%"} alt=""/>
          <span>Payments</span>
          <MdOutlineAccountBalanceWallet size={"4%"} color="white" />{" "}
          <span>Balance</span>
          <img
            src={capital}
            width={"4%"}
            height={"4%"}
            style={{ borderRadius: "30px" }}
            alt="capital"
          />
          <span>Capital</span>
          <TbReportAnalytics size={"4%"} color="white" />
          <span>Reports</span>
          <RiUser3Line
            size={"4%"}
            style={{
              backgroundColor: "#E4E4E4",
              padding: "1px",
              borderRadius: "7px",
              alignContent: "center",
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="carouseltwo">
      <img className="image" src={back} alt="Slide 1" />
      <div className="navbard">
        <Navbar />
      </div>

      <div className="carouselcontent">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1%",
          }}
        >
          <span>Today</span>
          <span>€ 24,324.10</span>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "1%",
              fontSize: "1.4vw",
            }}
          >
            <img width={"10%"} src={up} />
            <label>406.8 %</label>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1%",
          }}
        >
          <span style={{ justifyContent: "flex-end" }}>Transactions</span>
          <span style={{ justifyContent: "flex-end" }}>496</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "1%",
              justifyContent: "flex-end",
            }}
          >
            <img width={"10%"} src={up} />
            <label>220.9 %</label>
          </div>
        </div>
      </div>

      <div className="chart">
        <ResponsiveContainer width="88%" aspect={8}>
          <LineChart data={data} >
            <CartesianGrid
              stroke="#707070"
              strokeDasharray="0"
              horizontal={true}
              vertical={false}
            />
            <Line
              dataKey="value"
              stroke="#8884d8"
              dot={false}
              strokeWidth={1}
            />
            <YAxis tickCount={10} axisLine={false} hide={true} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="datas">
        <table className="payment-table">
          <thead>
            <tr>
              <th>Methods</th>
              <th>Amount</th>
              <th>Status</th>
              <th className="date">Date</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td>        
                  <img src={payment.image} alt="MasterCard" width={'7%'} style={{margin:'1vw'}}/> 
                </td>
                <td>{payment.amount}</td>
                <td className="paid" >
                  <div>
                    <FaRegCircleCheck />
                    <span>{payment.status}</span>
                  </div>
                  </td>
                <td className="date">{payment.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CarouselTwo;
