import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./adminbackoffice.scss";

import admin1 from "../../../../images/adminbackoffice1.png";
import admin2 from "../../../../images/adminbackoffice2.png";
import admin3 from "../../../../images/adminbackoffice3.png";

import merchant1 from "../../../../images/merchantbackoffice.png";
import merchant2 from "../../../../images/merchantbackoffice2.png";
import merchant3 from "../../../../images/merchantbackoffice3.png";

import cryptocommerce1 from "../../../../images/cryptocommerce.png";
import cryptocommerce2 from "../../../../images/cryptocommerce2.png";
import cryptocommerce3 from "../../../../images/cryptocommerce3.png";

import companybackoffice from "../../../../images/companybackoffice.png";
import companybackoffice2 from "../../../../images/companybackoffice2.png";
import companybackoffice3 from "../../../../images/companybackoffice3.png";

import panel from "../../../../images/panel.png";

import comingsoon from "../../../../images/comingsoon.mp4";

import CarouselTwo from "../../../carousels/homepagecarousel/carouseltwo/carouseltwo";

// import { Swiper, SwiperSlide, SwiperCore } from "swiper/react";
// import "swiper/css"; // core Swiper
// import "swiper/css/navigation"; // Navigation module CSS

// import { Navigation } from "swiper/modules";

const backOffices = {
  admin: {
    title: "Admin Back Office",
    descriptions: [
      "The Admin Back Office in Pay Layer Core is the nerve center where all the action happens. It’s the command post for managing the comprehensive features you’ve seen across all panels. From this pivotal hub, administrators can orchestrate integrations, control payment methods, and oversee the entire operation with precision",
      "It’s the definitive tool for administrators who demand efficiency, control, and scalability, allowing for a seamless management experience across all aspects of the Pay Layer Core system.",
    ],
    images: [
      { src: admin1, alt: "Description of Image 1" },
      { src: admin2, alt: "Description of Image 2" },
      { src: admin3, alt: "Description of Image 2" },
    ],
  },

  merchant: {
    title: "Merchant Back Office",
    descriptions: [
      "Navigate the financial landscape of your business with the Merchant Back Office from Pay Layer Core. Designed for merchant administrators, this control center equips you with essential tools for meticulous oversight. ",
      "Track and analyze transactions, delve into detailed reports, manage settlements, oversee reconciliation processes, and fine-tune integrations, all through an intuitive interface. Empower your business with the capacity to streamline operations, enhance financial accuracy, and drive growth—all from one pivotal dashboard.",
    ],
    images: [
      { src: merchant1, alt: "Description of Image 1" },
      { src: merchant2, alt: "Description of Image 2" },
      { src: merchant3, alt: "Description of Image 2" },
    ],
  },

  cryptocommerce: {
    title: "Crypto Commerce",
    descriptions: [
      "Harness the power of blockchain technology with Pay Layer Core’s Crypto Commerce capabilities. This sophisticated system, currently operating on the Tron network, offers a robust suite of features for cryptocurrency transactions.",
      " With functionality for handling deposits, withdrawals, and comprehensive reporting, it provides a secure and transparent financial environment for users. As Pay Layer Core evolves, expect expansions onto new networks, broadening the horizon for crypto commerce while maintaining the convenience and security you trust.",
    ],
    images: [
      { src: cryptocommerce1, alt: "Description of Image 1" },
      { src: cryptocommerce2, alt: "Description of Image 2" },
      { src: cryptocommerce3, alt: "Description of Image 2" },
    ],
  },

  companybackoffice: {
    title: "Company Back Office",
    descriptions: [
      "Enhance your operational efficiency with Pay Layer Core’s Company Back Office. This dedicated panel allows firms to closely monitor their transactions, delve into comprehensive reports, and manage settlements and reconciliations with precision.",
      "It supports the oversight of multiple integrations, providing a centralized control point for all financial operations, ensuring streamlined processes and improved financial accuracy.",
    ],
    images: [
      { src: companybackoffice, alt: "Description of Image 1" },
      { src: companybackoffice2, alt: "Description of Image 2" },
      { src: companybackoffice3, alt: "Description of Image 2" },
    ],
  },
  panel: {
    title: "Panel",
    descriptions: [
      "Stay on top of your financial flows with Pay Layer Core’s panel service. This essential service provides a streamlined view of all investment and withdrawal activities, enabling efficient tracking and management of your operations. With real-time updates and comprehensive data visibility, ensure your financial transactions are always under control.",
    ],
    images: [{ src: panel, alt: "Description of Image 1" }],
  },
  reportingservice: {
    title: "Reporting Service",
    descriptions: [
      "Gain unparalleled insights with Pay Layer Core's advanced reporting tools. Our platform provides comprehensive analytics to help you understand transaction trends, monitor merchant performance, and optimize your financial operations. With real-time data at your fingertips, make informed decisions that drive your business forward.",
    ],
    component: CarouselTwo,
  },
};

const BackOfficePage = ({ officeType }) => {
  const [carouselSize, setCarouselSize] = useState(70);
  const officeData = backOffices[officeType];

  useEffect(() => {
    const handleScroll = () => {
      const newSize = Math.max(66, 70 - (window.scrollY / 70) * 10);
      setCarouselSize(newSize);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!officeData) {
    // Veri yoksa daha spesifik bir yükleme uyarısı ver
    return (
      <div className="video-container">
        <div className="video-overlay">
          <span>Coming Soon...</span>
        </div>
        <video className="fullscreen-video" autoPlay loop muted>
          <source src={comingsoon} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  return (
    <div className="backoffice">
      <div className="headerpay">
        <span>{officeData.title}</span>
        {officeData.descriptions.map((desc, index) => (
          <span key={index}>{desc}</span>
        ))}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "5vh",
          marginBottom: "5vh",
        }}
      >
        <div
          style={{
            width: `${carouselSize}%`,
            borderRadius: "20px",
            boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Carousel indicators={false}>
            {officeType === "reportingservice" ? (
              <div className="imagearea">
                <officeData.component />
              </div>
            ) : officeData.images ? (
              officeData.images.map((image, index) => (
                <Carousel.Item key={index}>
                  <div className="imagearea">
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className="image"
                    />
                  </div>
                </Carousel.Item>
              ))
            ) : (
              <div>No images available</div> // images yoksa bu mesajı göster
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default BackOfficePage;
