import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./management.scss"; // Genel stil dosyası

import paymentmethods from "../../../images/paymentmethodsmanagement.png";
import poolsystem from "../../../images/poolsystem.png";
import transactionallimitsmanagement from "../../../images/transactionallimitmanagement.png";
import merchantmanagement from "../../../images/merchantmanagement.png";
import companymanagement from "../../../images/companymanagement.png";

const management = {
  paymentmethods: {
    title: "Payment Methods Management",
    descriptions: [
      "Pay Layer Core’s Payment Methods feature provides an agile and comprehensive management solution, enabling real-time customization of payment options to align with evolving business demands and customer preferences, while ensuring compliance with current financial trends and regulations.",
    ],
    images: { src: paymentmethods, alt: "paymentmethods" },
  },

  poolsystem: {
    title: "Pool System",
    descriptions: [
      "Pay Layer Core’s Pooling System ensures consistent and efficient payment distributions to merchants by managing funds flexibly and forecasting revenues without the risk of insufficient balances.",
    ],
    images: { src: poolsystem, alt: "poolsystem" },
  },

  transactionallimitsmanagement: {
    title: "Transactional Limits Management",
    descriptions: [
      "Efficient capital distribution by allocating funds across accounts within predefined boundaries, ensuring reliable and consistent payments.",
    ],
    images: {
      src: transactionallimitsmanagement,
      alt: "transactionallimitsmanagement",
    },
  },

  merchantmanagement: {
    title: "Merchant Management",
    descriptions: [
      "Pay Layer Core’s Merchant Management feature is a strategic tool that optimizes the payment ecosystem by broadening payment options through merchant partnerships, offering oversight of operations and compliance, and fine-tuning performance metrics.",
    ],
    images: { src: merchantmanagement, alt: "merchantmanagement" },
  },

  companymanagement: {
    title: "Company Management",
    descriptions: [
      "Pay Layer Core’s Company Management feature acts as a central platform, offering critical flexibility in financial operations by enabling customization of company profiles and transactional dynamics to fit strategic business objectives.",
    ],
    images: { src: companymanagement, alt: "companymanagement" },
  },
};

const Features = ({ officeType }) => {
  const officeData = management[officeType];
  const [carouselSize, setCarouselSize] = useState(70);

  useEffect(() => {
    const handleScroll = () => {
      const newSize = Math.max(60, 70 - (window.scrollY / 70) * 10);
      setCarouselSize(newSize);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="management">
      <div className="headerpay">
        <span>{officeData.title}</span>
        {officeData.descriptions.map((desc, index) => (
          <span key={index}>{desc}</span>
        ))}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "5vh",
          marginBottom: "5vh",
        }}
      >
        <div
          style={{
            width: `${carouselSize}%`,
            borderRadius: "20px",
            boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Carousel indicators={false}>
            <Carousel.Item>
              <div className="imagearea">
                <img
                  src={officeData.images.src}
                  alt={officeData.images.alt}
                  className="image"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Features;
