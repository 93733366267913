import React from "react";
import { LiaAngleRightSolid } from "react-icons/lia";
import art from "../../../../images/art.jpg";
import card from "../../../../images/credit-card.png";
import crypto from "../../../../images/bitcoin.png";
import bank from "../../../../images/bank.png";
import applepay from "../../../../images/apple-logo.png";
import "./carouselone.scss";

const Carouselone = () => {
  return (
    <div className="imagearea">
      <img className="image" src={art} alt="Slide 1" />
      <div className="card">
        <h3>Choose a payment method:</h3>
        <button>Pay</button>
        <svg
          width="80%"
          height="1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "0 auto", justifyContent: "center" }}
        >
          <line x1="0" y1="0" x2="100%" y2="0" stroke="#707070" />
        </svg>
        {[
          { image: bank, text: "Bank Transfer" },
          { image: crypto, text: "Cryptocurrency" },
          { image: card, text: "Cards" },
          { image: applepay, text: "Apple Pay" },
        ].map((item, index) => (
          <>
            <div className="cartitems">
              <img
                src={item.image}
                alt="resim"
                style={{
                  width: "13%",
                  marginTop: "1%",
                  marginBottom: "1%",
                }}
              />
              <span
                style={{
                  color: "black",
                  fontSize: "1.5vw",
                  marginRight: "auto",
                  marginLeft: "1vw",
                }}
              >
                {item.text}
              </span>
              <LiaAngleRightSolid color="black" />
            </div>
            <svg
              width="80%"
              height="1"
              xmlns="http://www.w3.org/2000/svg"
              style={{display:'flex', alignItems:'center',    margin: '0 auto'            }}
            >
              <line
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
                stroke="#707070"
                strokeWidth="1"
              />
            </svg>
          </>
        ))}
      </div>
    </div>
  );
};

export default Carouselone;
