import React from "react";
import "./navbar.scss";
import { FiArrowUpRight } from "react-icons/fi";
import { LiaAngleDownSolid } from "react-icons/lia";

import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import art from "../../images/art.jpg";
import logo from "../../images/logo.png"; 
import docs from "../../images/file-backup.png"; 

import admin from "../../images/navbarimage/admin.png"; 
import company from "../../images/navbarimage/company.png"; 
import mercant from "../../images/navbarimage/merchant.png"; 
import panel from "../../images/navbarimage/panel.png"; 
import crypto from "../../images/navbarimage/crypto.png"; 
import report from "../../images/navbarimage/report.png"; 


import payment from "../../images/navbarimage/payment.png"; 
import poolsystem from "../../images/navbarimage/poolsystem.png"; 
import transactional from "../../images/navbarimage/transactional.png"; 
import merchantmanagement from "../../images/navbarimage/merchantmanagement.png"; 
import companymanagement from "../../images/navbarimage/companymanagement.png"; 


import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown"; // Dropdown bileşenini ekleyin

import paylayerApiDoc from './docs/Paylayercore_API_Doc_V002.pdf';


function CustomDropdownItem({ name, flag, subtitle }) {
  return (
    <Dropdown.Item
      style={{ display: "flex", flexDirection: "row"}}
    >
      <img
        src={art}
        alt={`${name} flag`}
        className="flag-icon"
        style={{
          width: "35px",
          height: "35px",
          borderRadius: "30px",
          justifyContent: "center",
          alignSelf: "center",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "0.8rem",
          marginLeft: "0.4vw",
          fontWeight: "500",
          alignSelf: "center",
        }}
      >
        <span className="language-name">{name}</span>
        <span style={{ textDecorationLine: "underline" }}>{subtitle}</span>
      </div>
    </Dropdown.Item>
  );
}

const product = [
  {
    name: "Admin Back Office",
    logo: admin,
    path: "/adminbackoffice",
  },
  {
    name: "Company Back Office",
    logo: company,
    path: "/companybackoffice",
  },
  {
    name: "Merchant Back Office",
    logo: mercant,
    path: "/merchantbackoffice",
  },
  { name: "Panel", 
    logo: panel, 
    path: "/panel" 
  },
  {
    name: "Crypto Commerce",
    logo: crypto,
    path: "/cryptocommerce",
  },
  {
    name: "ReportingService",
    logo: report,
    path: "/reportingservice",
  },
];

function Products() {
  return (
    <div className="services-grid">
      {product.map((product) => (
        <a
          href={product.path}
          key={product.name}
          className="service-card"
          style={{ textDecoration: "none" }}
        >
          <img src={product.logo} alt={product.name} width={20} />
          <span style={{ color: "white" }}>{product.name}</span>
        </a>
      ))}
    </div>
  );
}



const features = [
  {
    name: "Payment Methods",
    logo: payment,
    path: "/paymentmethods",
  },
  {
    name: "Pool System",
    logo: poolsystem,
    path: "/poolsystem",
  },
  {
    name: "Transactional Limits Management",
    logo: transactional,
    path: "/transactionallimitsmanagement",
  },
  { name: "Merchant Management", 
    logo: merchantmanagement, 
    path: "/merchantmanagement" 
  },
  {
    name: "Company Management",
    logo: companymanagement,
    path: "/companymanagement",
  },
]

function Features() {
  return (
    <div className="services-grid">
      {features.map((feature) => (
         <a
         href={feature.path}
         key={feature.name}
         className="service-card"
         style={{ textDecoration: "none" }}
       >
         <img src={feature.logo} alt={feature.name} width={20} />
         <span style={{ color: "white" }}>{feature.name}</span>
       </a>
      ))}
    </div>
  );
}

const NavbarCom = () => {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact");
  };
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-black">
      <Container>
        <Navbar.Brand className="me-5" href="/">
          <img src={logo} alt="s" width={150} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            {/* Product */}
            <NavDropdown
              title={
                <span>
                  Product <LiaAngleDownSolid />
                </span>
              }
              id="collapsible-nav-dropdown"
              className="me-4 rounded custom-nav-dropdown"
            >
              <NavDropdown.Item href="/onlinepayment" className="custom-item">
                <Products />
              </NavDropdown.Item>
            </NavDropdown>

            {/* Features */}
            <NavDropdown
              title={
                <span>
                  Features <LiaAngleDownSolid />
                </span>
              }
              id="collapsible-nav-dropdown"
              className="me-4 rounded custom-nav-dropdown"
            >
              <NavDropdown.Item href="#action/3.1" className="custom-item">
                <Features />
                </NavDropdown.Item>
            </NavDropdown>

            {/* DEVELOPERS */}
            <NavDropdown
              title={
                <span>
                  Developers <LiaAngleDownSolid />
                </span>
              }
              id="collapsible-nav-dropdown"
              className="me-4 rounded custom-nav-dropdown "
            >
              <NavDropdown.Item  href={paylayerApiDoc} target="_blank" className="custom-item">  <span  style={{
                    display: "flex",
                    alignItems: "center",
                    color:'#8E8E8E'
                  }}>Propay API</span>
                <div style={{display:'flex', alignItems:'center'}}>
                 
                <img src={docs} width={17} height={17}/>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color:'white',
                    fontSize:'1.8vh',
                    marginLeft:'0.2vw'
                  }}
                >
                  Docs <FiArrowUpRight style={{ marginLeft: "0.2vw" }} />
                </span>
                </div>
             
                <span>Explore our API documentation and guides</span>
              </NavDropdown.Item>
            </NavDropdown>

            {/* Pricing */}
            <Nav.Link href="/pricing" className="me-4 custom-nav-dropdown ">
              Pricing
            </Nav.Link>

            {/* CONTACT */}
            <NavDropdown
              title={
                <span>
                  Contact Us<LiaAngleDownSolid />
                </span>
              }
              id="collapsible-nav-dropdown"
              className="me-4 rounded custom-nav-dropdown "
            >
              <NavDropdown.Item onClick={goToContact} className="custom-item">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Contact Sales <BsArrowRight />
                </span>
                <span>Discover how we can help your business</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="m-auto">
          
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarCom;
