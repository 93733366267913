import React from "react";
import "./pagesecond.scss";
import trx from "../../images/trx.png";
import visa from "../../images/bitcoin.png";
import bankicon from "../../images/bank.png";
import master from "../../images/master.png";
import papara from "../../images/papara_icon.png";
import google from "../../images/google.png";
import applepay from "../../images/apple-pay-3.png";
import card from "../../images/credit-card.png";
import phone from "../../images/phone2.png";

const Pagesecond = () => {
 

  // image array
  const images = [bankicon, master, visa, trx, papara, google, card, applepay];

  const colors = [
    "#D2E0F7",
    "#000000",
    "#FFE136",
    "#F7F7F7",
    "#EDEAEA",
    "#E3D29D",
    "#61469A",
    "#E6E6E6",
  ];
  
  // function to create a gradient given a single color
  const createGradient = (color) => {
    // vlend each color with a lighter version (closer to white) for a smooth gradient
    return `linear-gradient(${color}, ${lightenColor(color, 0.2)} 85%, ${lightenColor(color, 0.4)} 100%)`;
  }
  
  // helper function to lighten a color
  const lightenColor = (color, lightness) => {
    let [r, g, b] = color.match(/\w\w/g).map((c) => parseInt(c, 16));
    let increase = Math.floor(lightness * 255); // Calculate the increase based on lightness
    r = Math.min(255, r + increase);
    g = Math.min(255, g + increase);
    b = Math.min(255, b + increase);
    return `rgb(${r}, ${g}, ${b})`;
  }
  
  const BoxContent = ({ color, image }) => (
    <div
      className="box"
      style={{
        backgroundImage: createGradient(color),
        width: '100%', 
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={image}
        style={{
          maxWidth: "80%", 
          maxHeight: "80%",
        }}
        alt=""
      />
    </div>
  );
  
  // render boxes with icons and background gradients
  const renderBoxes = images.map((image, index) => (
    <BoxContent
      key={index}
      color={colors[index % colors.length]}
      image={image}
    />
  ));

  return (
    <div className="secondcontent">
      <div className="textarea">
        <span className="textheader">TRANSFORM YOUR BUSINESS WITH PAY LAYER CORE—A POWERFUL, ALL-IN-ONE PAYMENT PLATFORM THAT SIMPLIFIES HOW YOU HANDLE TRANSACTIONS</span>
        <span className="textsubtitle">With our platform, integrating a vast array of payment methods becomes seamless, from bank transfer to e-wallets and beyond, including our innovative crypto commerce system with an integrated wallet.</span>
      </div>
      <div className="cardareacontent">
        <div className="cardareaone">
          <span style={{ fontSize: "1.7vw", fontWeight: "600",textAlign:'center'  }}>
          Payment solutions
          </span>
          <span style={{ fontSize: "1.2vw", fontWeight: "300",textAlign:'center'  }}>
          Unlock new growth opportunities with tailored solutions that scale with your business needs.
          </span>
          <div className="box-row">{renderBoxes.slice(0, 4)}</div>
          <div className="box-row">{renderBoxes.slice(4, 8)}</div>
        </div>
        <div className="cardareasecond">
          <span style={{ fontSize: "1.7vw", fontWeight: "600"  }}>
            Get paid faster
          </span>
          <span style={{ fontSize: "1.7vw", fontWeight: "300" }}>
            Create and share secure payment links.
          </span>
          <img src={phone} alt="phone"/>
        </div>
      </div>
    </div>
  );
};

export default Pagesecond;
