import React, {useRef} from "react";
import "./homepage.scss"; 
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Carouselone from "../carousels/homepagecarousel/carouselone/carouselone";
import CarouselTwo from "../carousels/homepagecarousel/carouseltwo/carouseltwo";

function ImageAreaCarousel() {
  const carouselRef = useRef(null);

  const handleClick = () => {
    carouselRef.current.next();
  };

  return (
    <Carousel controls={false} interval={null} ref={carouselRef} indicators={false} >
      <Carousel.Item onClick={handleClick}>
        <div className="firstcarousel">
            <Carouselone />
        </div>
      </Carousel.Item>
      <Carousel.Item onClick={handleClick}>
        <div className="secondcarousel">
          <CarouselTwo /> 
        </div>
      </Carousel.Item>
    </Carousel>
  );
}


const Homepage = () => {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate('/contact'); 
  };

  return (
    <div className="contents">
      <div className="textarea">
        <span>Pay Layer Core </span>
        <span>Enhance your transactions: rapid integration meets efficient Payment Solutions </span>
       <div className="buttonarea">
        <button onClick={goToContact}>Contact</button>
      </div>
      </div>
      <div style={{width:'80%',height:'100%', marginLeft:'10%',}}>
        <ImageAreaCarousel /> 
      </div>  
    </div>
  );
};

export default Homepage;
