import React from "react";
import "./Contact.scss"; // SCSS dosyanızı import edin
import { countries } from "countries-list";

const Contact = () => {
  const countriesArray = Object.entries(countries).map(
    ([countryCode, countryData]) => ({
      code: countryCode,
      name: countryData.name,
    })
  );

  return (
    <div className="contactsection">
      <div className="header">
        <span>Contact Us</span>
      </div>
      <div className="description">
        <div className="description-item">
          Ready to enhance your payment operations?
        </div>
        <div className="description-item">
          Get in touch with our team today for more information and discover
          what Pay Layer Core can do for you.
        </div>
      </div>
      <div className="form">
        <form>
          <div className="form-row">
            {" "}
            {/* name lastname*/}
            <div className="form-group">
              <label htmlFor="name">First Name*</label>
              <input
                type="text"
                id="name"
                name="firstname"
                placeholder="Enter your first name…"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Last Name*</label>
              <input
                type="name"
                id="lastname"
                name="lastmane"
                placeholder="Enter your last name…"
              />
            </div>
          </div>
          <div className="form-row">
            {" "}
            {/* businessemail companyname*/}
            <div className="form-group">
              <label htmlFor="name">Business email *</label>
              <input
                type="text"
                id="name"
                name="email"
                placeholder="Enter your email…"
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyname">Company name *</label>
              <input
                type="name"
                id="companyname"
                name="companyname"
                placeholder="Enter your company name…"
              />
            </div>
          </div>
          <div className="form-row">
            {" "}
            {/* companywebsite phone*/}
            <div className="form-group">
              <label htmlFor="name">Company website *</label>
              <input
                type="text"
                id="website"
                name="website"
                placeholder="Enter your website"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone *</label>
              <input
                type="phone"
                id="phone"
                name="phone"
                placeholder="Enter your phone…"
              />
            </div>
          </div>
          <div className="form-row">
            {" "}
            {/* businesslocation */}
            <div className="form-group">
              <label htmlFor="location">
                Where is your business located? *
              </label>
              <select id="location" name="location" defaultValue="">
                <option value="" disabled>
                  Select a country
                </option>
                {countriesArray.map((country) => (
                  <option value={country.code} key={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            {" "}
            {/* textarea*/}
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                rows={14} // İsteğe bağlı: Metin alanının varsayılan satır sayısı
                cols={50} // İsteğe bağlı: Metin alanının varsayılan sütun sayısı
                placeholder="How can we help you? *"
              />
            </div>
          </div>
          <div className="form-row" style={{ alignContent: "center" }}>
            {" "}
            {/* checkbox */}
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input type="checkbox" name="isChecked" />
              <label
                style={{
                  color: "#888888",
                  marginLeft: "5px",
                  fontWeight: "400",
                }}
              >
                Yes I’d like to receive news and updates from Vanguardlink. For
                more information about how we handle your data, please read our
                privacy statement.
              </label>
            </div>
          </div>
          <div className="form-row">
            {/* submit */}
            <div className="form-group">
              <button type="submit" name="submit">
                {" "}
                Send Message{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
