import React from "react";
import "./pricing.scss";
import crypto from "../../../images/bitcoin.png";
import bank from "../../../images/bank.png";
import papara from "../../../images/papara_icon.png";
import card from "../../../images/credit-card.png";
import applepay from "../../../images/apple-logo.png";
import google from "../../../images/google.png";
import wallet from "../../../images/wallet.png";


const Pricing = () => {
  const pricinginfo = [
    {
      brand: "Cryptocurrency",
      image: crypto, 
    },
    {
      brand: "Bank Transfer",
      image: bank, 
    
    },
    {
      brand: "Papara",
      image: papara, 
    },    
    {
      brand: "Credit Card",
      image: card, 
    },
    {
      brand: "Apple Pay",
      image: applepay, 
    },   
    {
      brand: "Google Pay",
      image: google, 
    },
  ];

  return (
    <div className="pricingpage">
      <div className="headerpricing">
        <span>Pricing for Payment Solutions</span>
      </div>
      <div className="contentpricing">
        <div className="headercontent">
            <img src={wallet} alt="wallet" width={37}/>
            <header className="header">Payment Methods</header>
        </div>
        <div className="card-container">
          {pricinginfo.map((card, cardIndex) => (
            <div className="info" key={cardIndex}>
              <div className="logo-container">
                <img src={card.image}  width={37} alt="img"/>
                <span className="brand">{card.brand}</span>
                <span className="subtitle">{card.subtitle}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
